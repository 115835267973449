// src/components/Navbar.jsx

import { FaBars, FaTimes } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";

const Navbar = () => {
	const [nav, setNav] = useState(false);

	const handleClick = () => setNav(!nav);

	const navLinks = [
		{ id: 1, name: "Home", path: "/" },
		{ id: 2, name: "About Us", path: "/about" },
		{ id: 3, name: "Locations", path: "/locations" },
		{ id: 4, name: "Rental", path: "/rental" },
		{ id: 5, name: "Contact", path: "/contact" },
	];

	return (
		<header className="w-full bg-black text-white fixed top-0 z-50">
			<div className="container mx-auto flex justify-between items-center px-6 py-2">
				{/* Logo */}
				<Link to="/">
					<img
						src={"/logo.png"}
						alt="DERA FILM Logo"
						className="w-20 h-20 object-contain"
					/>
				</Link>

				{/* Desktop Menu */}
				<nav className="hidden md:flex space-x-6">
					{navLinks.map(({ id, name, path }) => (
						<NavLink
							key={id}
							to={path}
							exact
							activeClassName="text-red-500"
							className="hover:text-red-500 transition-colors"
						>
							{name}
						</NavLink>
					))}
				</nav>

				{/* Mobile Menu Icon */}
				<div className="md:hidden cursor-pointer" onClick={handleClick}>
					{!nav ? <FaBars size={24} /> : <FaTimes size={24} />}
				</div>

				{/* Mobile Menu */}
				{nav && (
					<nav className="absolute top-16 left-0 w-full bg-black text-white flex flex-col items-center space-y-6 py-6 shadow-lg">
						{navLinks.map(({ id, name, path }) => (
							<NavLink
								key={id}
								to={path}
								exact
								activeClassName="text-red-500"
								className="hover:text-red-500 transition-colors text-xl"
								onClick={handleClick}
							>
								{name}
							</NavLink>
						))}
					</nav>
				)}
			</div>
		</header>
	);
};

export default Navbar;
