// src/components/AboutUs.jsx

import { FaCheckCircle } from "react-icons/fa"; // Using Font Awesome icons
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import React from "react";
import { motion } from "framer-motion";

/**
 * AboutUs Component
 */
const AboutUs = () => {
	return (
		<div className="bg-white text-black">
			{/* Navbar */}
			<Navbar />

			{/* Spacer for fixed header */}
			<div className="h-24"></div>

			{/* Hero Section */}
			<HeroSection />

			{/* Vision & Mission Section */}
			<VisionMissionSection />

			{/* Who We Are Section */}
			<WhoWeAreSection />

			{/* Why We're Different Section */}
			<WhyDifferentSection />

			{/* CEO Section */}
			<CEOSection />

			{/* Footer */}
			<Footer />
		</div>
	);
};

export default AboutUs;

/* -------------------------- Sub-Components -------------------------- */

/**
 * Hero Section
 */
const HeroSection = () => {
	return (
		<section className="bg-[#394953] text-white py-20">
			<div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-between px-6">
				{/* Left Content */}
				<motion.div
					className="md:w-1/2"
					initial={{ opacity: 0, x: -50 }}
					animate={{ opacity: 1, x: 0 }}
					transition={{ duration: 1 }}
				>
					<h1 className="text-4xl font-bold mb-6">
						DERA FILM CONNECTS FILMMAKERS AROUND THE WORLD
					</h1>
					<p className="text-lg mb-6">
						Connecting Filmmakers Worldwide Our goal is to collaborate with
						international production companies Worldwide to create impactful TV
						or film content. We offer you the top creative professionals and
						cutting-edge resources to bring your vision to life. From concept to
						completion, we offer seamless support through our network of experts
						and state-of-the-art facilities across the Balkans and Europe.
						Whether you’re looking to entertain, inform, or inspire, we’re here
						to make it happen.
					</p>
				</motion.div>
				{/* Right Image */}
				<motion.div
					className="md:w-1/2 mb-8 md:mb-0"
					initial={{ opacity: 0, x: 50 }}
					animate={{ opacity: 1, x: 0 }}
					transition={{ duration: 1 }}
				>
					<img
						src="/about.png" // Ensure this image is in the public folder
						alt="Introduction to DERA FILM"
						className="w-11/12 mx-auto rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
						loading="lazy"
					/>
				</motion.div>
			</div>
		</section>
	);
};

/**
 * Vision & Mission Section
 */
const VisionMissionSection = () => {
	return (
		<section className="py-20 bg-gray-100">
			<div className="container mx-auto px-6">
				<h2 className="text-3xl font-bold text-center mb-12 text-red-500">
					Vision & Mission
				</h2>
				{/* Vision */}
				<motion.div
					className="mb-16"
					initial={{ opacity: 0, y: 50 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{ duration: 1 }}
				>
					<div className="flex items-start mb-6">
						<FaCheckCircle className="text-red-500 w-6 h-6 mt-1 mr-4" />
						<div>
							<h3 className="text-2xl font-semibold mb-2 text-red-500">
								OUR VISION
							</h3>
							<p className="text-lg">
								To make the hiring of our production services and crew easier
								than ever before for your Pre-Production, Production, and
								Post-Production needs in the Balkans and Europe.
							</p>
						</div>
					</div>
				</motion.div>
				{/* Mission */}
				<motion.div
					className="mb-16"
					initial={{ opacity: 0, y: 50 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{ duration: 1, delay: 0.2 }}
				>
					<div className="flex items-start mb-6">
						<FaCheckCircle className="text-red-500 w-6 h-6 mt-1 mr-4" />
						<div>
							<h3 className="text-2xl font-semibold mb-2 text-red-500">
								OUR MISSION
							</h3>
							<p className="text-lg">
								To solve the biggest problem most productions face: finding and
								hiring qualified professionals by providing the tools and
								resources needed on our network.
							</p>
						</div>
					</div>
				</motion.div>
			</div>
		</section>
	);
};

/**
 * Who We Are Section
 */
const WhoWeAreSection = () => {
	return (
		<section className="py-20 bg-white">
			<div className="container mx-auto px-6">
				<h2 className="text-3xl font-bold text-center mb-12 text-red-500">
					Who We Are
				</h2>
				<div className="flex flex-col lg:flex-row items-center justify-between">
					{/* Left Image */}
					<motion.div
						className="lg:w-1/2 mb-8 lg:mb-0"
						initial={{ opacity: 0, x: -50 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						transition={{ duration: 1 }}
					>
						<img
							src="/who-we-are.png" // Ensure this image is in the public folder
							alt="Who We Are"
							className="w-full max-w-lg mx-auto rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
							loading="lazy"
						/>
					</motion.div>
					{/* Right Text */}
					<motion.div
						className="lg:w-1/2 lg:pl-12"
						initial={{ opacity: 0, x: 50 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						transition={{ duration: 1 }}
					>
						<p className="text-lg mb-6">
							Established in 2012 and headquartered in Kosovo, Dera Film is a
							leading film production company serving the Balkan and European
							markets. We provide end-to-end production services, including
							expert professionals—directors, cinematographers, and
							editors—alongside cutting-edge facilities and breathtaking
							locations.
						</p>
						<p className="text-lg">
							At Dera Film, we pride ourselves on delivering excellence in every
							project. With a commitment to quality, creativity, and
							professionalism, we work tirelessly to bring your vision to life,
							ensuring results that exceed expectations.
						</p>
					</motion.div>
				</div>
			</div>
		</section>
	);
};

/**
 * Why We're Different Section
 */
const WhyDifferentSection = () => {
	const features = [
		{
			title: "Comprehensive Services",
			description:
				"We provide a full spectrum of production services, ensuring you have everything you need in one place.",
		},
		{
			title: "Experienced Team",
			description:
				"Our team consists of industry veterans who bring expertise and passion to every project.",
		},
		{
			title: "Reliable Support",
			description:
				"We offer consistent and dependable support throughout your production journey.",
		},
	];

	return (
		<section className="py-20 bg-gray-100">
			<div className="container mx-auto px-6">
				<h2 className="text-3xl font-bold mb-12 text-red-500 text-center">
					Why We're Different
				</h2>
				<div className="flex flex-col lg:flex-row items-center justify-between">
					{/* Text Content */}
					<motion.div
						className="lg:w-1/2 lg:pr-12 mb-8 lg:mb-0"
						initial={{ opacity: 0, x: 50 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						transition={{ duration: 1 }}
					>
						<div className="space-y-6">
							{features.map((feature, index) => (
								<div key={index} className="flex items-start">
									<FaCheckCircle className="text-red-500 w-6 h-6 mt-1 mr-4" />
									<div>
										<h4 className="text-xl font-semibold text-red-500">
											{feature.title}
										</h4>
										<p className="text-lg">{feature.description}</p>
									</div>
								</div>
							))}
						</div>
					</motion.div>
					{/* Right Image */}
					<motion.div
						className="lg:w-1/2 mb-8 lg:mb-0"
						initial={{ opacity: 0, x: -50 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						transition={{ duration: 1 }}
					>
						<img
							src="/why-different1.png" // Ensure this image is in the public folder
							alt="Why We're Different"
							className="w-full max-w-xl mx-auto rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
							loading="lazy"
						/>
					</motion.div>
				</div>
			</div>
		</section>
	);
};

/**
 * CEO Section
 */
const CEOSection = () => {
	return (
		<section className="py-20 bg-white">
			<div className="container mx-auto px-6">
				<div className="flex flex-col lg:flex-row items-center justify-center">
					{/* CEO Image */}
					<motion.div
						className="lg:w-1/3 mb-8 lg:mb-0"
						initial={{ opacity: 0, x: -50 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						transition={{ duration: 1 }}
					>
						<img
							src="/ceo.png" // Ensure this image is in the public folder
							alt="Latif Hasolli - CEO"
							className="w-full max-w-xs mx-auto rounded-md shadow-lg transform hover:scale-105 transition-transform duration-300"
							loading="lazy"
						/>
					</motion.div>
					{/* CEO Description */}
					<motion.div
						className="lg:w-2/3 lg:pl-12"
						initial={{ opacity: 0, x: 50 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						transition={{ duration: 1 }}
					>
						<h3 className="text-2xl font-semibold mb-4 text-red-500">
							LATIF HASOLLI
						</h3>
						<p className="text-lg mb-4">
							Latif Hasolli as Producer, Director of photography, Film and TV
							Editor, with years of experience in producing and a passion for
							storytelling, he became a successful Film & TV Producer Worldwide!
						</p>
						<p className="text-lg mb-4">
							Recognizing the need for better local services and logistical
							support for international film crews in the Balkans, Latif founded
							a company Dera Film to provide complete production solutions. His
							mission is to showcase the Balkan region’s rich cultural heritage
							and position Kosovo, Albania… as top destinations for filmmakers
							Worldwide.
						</p>
						<a
							href="http://latifhasolli.com"
							target="_blank"
							rel="noopener noreferrer"
							className="text-red-500 hover:underline text-lg"
						>
							Visit Latif Hasolli's Website
						</a>
					</motion.div>
				</div>
			</div>
		</section>
	);
};

/**
 * Footer Component
 */
const Footer = () => {
	return (
		<footer className="w-full bg-black text-white py-6">
			<div className="container mx-auto text-center px-6">
				<ul className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6 mb-4">
					<li>
						<NavLink
							to="/"
							exact
							className="hover:text-red-500 transition-colors"
						>
							Home
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/about"
							className="hover:text-red-500 transition-colors"
						>
							About Us
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/locations"
							className="hover:text-red-500 transition-colors"
						>
							Locations
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/rental"
							className="hover:text-red-500 transition-colors"
						>
							Rental
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/contact"
							className="hover:text-red-500 transition-colors"
						>
							Contact
						</NavLink>
					</li>
				</ul>
				<p>© 2024 Dera Film. All rights reserved.</p>
			</div>
		</footer>
	);
};
