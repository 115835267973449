// src/components/Rent.jsx

// src/components/Rent.jsx

import { AnimatePresence, motion } from "framer-motion";
import {
	FaBatteryFull,
	FaCamera,
	FaCog,
	FaFilter,
	FaLightbulb,
	FaTools,
	FaTv,
} from "react-icons/fa";
import React, { useState } from "react";

import { BsLaptop } from "react-icons/bs";
import { GiDeliveryDrone } from "react-icons/gi";
import Modal from "./Modal"; // Import the Modal component
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar"; // Assuming you already have a Navbar component

const rental = [
	{
		imgSrc: "/rental1.png",
		name: "Arri Alexa SXT Plus Package",
		description:
			"Includes 4K anamorphic & all licenses, ARRI Electronic Viewfinder 02, CODEX Reader, 2x Viewfinder Cable, External Power Cable, etc.",
	},
	{
		imgSrc: "/rent2.png",
		name: "Chrosziel MB450-R1",
		description: "Matte box for professional use.",
	},
	{
		imgSrc: "/rent4.png",
		name: "Arri sxt original case",
		description: "Arri Alexa digital motion picture camera system",
	},
	{
		imgSrc: "/rent5.png",
		name: "Arri Alexa Internally FSND Filter Set",
		description:
			"Filters include 0.3, 0.6, 0.9, 1.2, 1.5, 1.8, 2.1, 2.4 for various lighting conditions.",
	},
	{
		imgSrc: "/rent6.png",
		name: "Blueshape Granite 290 Wh and Charger",
		description:
			"High-capacity battery packs for extended use with charger included.",
	},
	{
		imgSrc: "/rent7.png",
		name: "Tv Logic 5 inch Monitor",
		description: "Compact 5-inch monitor for precise camera output monitoring.",
	},
	{
		imgSrc: "/rent8.png",
		name: "Hollyland Mars 400 Pro Wirless Sdi",
		description: "Professional wireless video transmission system",
	},
	{
		imgSrc: "/rent9.png",
		name: "Sachtler Video 18 S2 Head System",
		description:
			"Includes ENG 2D Aluminum Tripod & Ground Spreader for stable camera support.",
	},
	{
		imgSrc: "/lente.png",
		name: "Sony Cine Alta 4K PL/T2 Full Set of 6 Lenses",
		description:
			"Prime lenses: 20mm, 25mm, 35mm, 50mm, 85mm, 135mm. Full metal construction, 9-blade iris.",
	},
	{
		imgSrc: "/monitor.png",
		name: "Panasonic BT-LH910 LCD 9” Video Field Monitor",
		description:
			'9" 1280x768 resolution, HDMI/SDI support, with advanced monitoring features.',
	},
	{
		imgSrc: "/rent10.png",
		name: "Sony FX6 Full-Frame Cinema Camera",
		description:
			"4K Full-Frame 10.2MP CMOS Exmor R Sensor, supports up to 4K120 and 1080p240, compact form factor.",
	},
	{
		imgSrc: "/teklente.png",
		name: "Sony FE 24-70mm f/2.8 GM II Lens (Sony E)",
		description:
			"Full-Frame | f/2.8 to f/22 Fast Standard Zoom Four XD Linear AF Motors, Floating Focus , Dust and Moisture-Resistant Construction",
	},
	{
		imgSrc: "/camcorder.png",
		name: "Sony PXW-FS7 4K XDCAM Super35 Camcorder",
		description:
			"Super 35 Sized CMOS Sensor, Sony E-Mount Carl, UHD Up to 60 FPS, HD Up to 180 FPS,4096 x 2160 Via External Recorder, XVAC-I, XVAC-L, MPEG-2",
	},
	{
		imgSrc: "/4lente.png",
		name: "Rokinon 24, 35, 50, 85mm T1.5 Cine DS Lens Bundle",
		description:
			"Cine lenses for Sony E Mount, great for cinema and video work.",
	},
	{
		imgSrc: "/teklentetjeter.png",
		name: "DJI Gimbal Ronin 1",
		description: "Stabilizer for smooth handheld shots.",
	},
	{
		imgSrc: "/dron.png",
		name: "Drone DJI MAVIC 2 PRO",
		description:
			"High-resolution drone for aerial cinematography with multiple intelligent flight modes.",
	},
	{
		imgSrc: "/drita.png",
		name: "2 x DAYLED 2000 DUAL COLOR",
		description:
			"With 220 W variable color temperature Power LED COB,Connection for mains and battery,Adjustable color temperature from 5600 K to 3200 K,",
	},
	{
		imgSrc: "/tekdrit.png",
		name: "2 x DAYLED 1000 DUAL COLOR",
		description:
			"With 110 W variable color temperature Power LED COB, Connection for mains and battery,Adjustable color temperature from 5600 K to 3200 K,",
	},
	{
		imgSrc: "/tjaterdrit.png",
		name: "2 x DAYLED 650 DUAL COLOR",
		description:
			"With 60 W variable color temperature Power LED COB, Connection for mains and battery,Adjustable color temperature from 5600 K to 3200 K,",
	},
	{
		imgSrc: "/grid.png",
		name: "2 x EGG CRATE GRID FOR SOFTBOX Egg crate grid for all Softboxes.",
	},
	{
		imgSrc: "/dritkatore.png",
		name: "2 x Lupo Softbox for Fresnels",
	},
	{
		imgSrc: "/stand.png",
		name: "COMPACT STANDLightweight stand for fixtures",
	},
	{
		imgSrc: "/stand2.png",
		name: "MASTER STANDMaster stand for fixtures (Black, 12')",
	},
	{
		imgSrc: "/renta11.png",
		name: "10 x DeSisti Magis 300/500/650W Fresnel Light with Stand Mount",
	},
	{
		imgSrc: "/ren12.png",
		name: "DJI GIMBAL Ronin 1",
	},
	{
		imgSrc: "/atomos.png",
		name: "External recorder monitor Atomos Ninja Inferno",
	},
	{
		imgSrc: "/appleretina.png",
		name: 'Apple 27" iMac Pro with Retina 5K Display',
		description:
			'2.3 GHz Intel Xeon W 18-Core, 64GB RAM, AMD Radeon Pro Vega 56, 27" 5K Retina Display.',
	},
	{
		imgSrc: "/macbookpro.png",
		name: "MacBook Pro 16-inch (Space Gray)",
		description:
			"Apple M1 Max chip, 32-core GPU, 1TB SSD, 16-inch Liquid Retina XDR display.",
	},
];

const Rent = () => {
	// State to manage the modal visibility and current image
	const [showModal, setShowModal] = useState(false);
	const [currentImg, setCurrentImg] = useState("");
	const [currentName, setCurrentName] = useState("");

	// Handler to open the modal with the clicked image
	const openModal = (imgSrc, name) => {
		setCurrentImg(imgSrc);
		setCurrentName(name);
		setShowModal(true);
	};

	// Helper function to determine the appropriate icon
	const getIcon = (name) => {
		const lowerName = name.toLowerCase();

		// Monitor Icons
		if (lowerName.includes("monitor")) return <FaTv />;

		// Drone Icons
		if (lowerName.includes("drone") || lowerName.includes("mavic"))
			return <GiDeliveryDrone />;

		// Camera Icons
		if (
			lowerName.includes("camera") ||
			lowerName.includes("camcorder") ||
			lowerName.includes("sxt") ||
			lowerName.includes("fs7")
		) {
			return <FaCamera />;
		}

		// Laptop/Computer Icons
		if (
			lowerName.includes("laptop") ||
			lowerName.includes("macbook") ||
			lowerName.includes("imac") ||
			lowerName.includes("computer")
		) {
			return <BsLaptop />;
		}

		// Gimbal Icon
		if (lowerName.includes("gimbal")) return <FaCog />;

		// Softbox Icons
		if (lowerName.includes("softbox") || lowerName.includes("egg crate"))
			return <FaLightbulb />;

		// Stand Icons
		if (lowerName.includes("stand")) return <FaTools />;

		// Light Icons
		if (
			lowerName.includes("light") ||
			lowerName.includes("led") ||
			lowerName.includes("fresnel")
		)
			return <FaLightbulb />;

		// Battery Icon
		if (lowerName.includes("battery")) return <FaBatteryFull />;

		// Specific Brand Matches
		if (lowerName.includes("chrosziel")) return <FaFilter />;
		if (lowerName.includes("arri")) return <FaCamera />;

		// If no condition matches, return Gear Icon
		return <FaCog />;
	};

	return (
		<div className="bg-gray-100 text-gray-800 min-h-screen flex flex-col">
			{/* Navbar */}
			<Navbar />

			{/* Page Container */}
			<div className="container mx-auto px-6 py-20 flex-grow">
				{/* Title */}
				<motion.h2
					className="text-5xl font-extrabold text-center m-16 text-red-500"
					initial={{ opacity: 0, y: -50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.8 }}
				>
					Rental Equipment
				</motion.h2>

				{/* Rental Listing */}
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12">
					{rental.map((item, index) => {
						const icon = getIcon(item.name);

						return (
							<motion.div
								key={index}
								className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-2xl transform hover:scale-105 transition-transform duration-300 ease-in-out relative group"
								initial={{ opacity: 0, y: 50 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.6, delay: index * 0.1 }}
							>
								{/* Icon Container */}
								<motion.div
									className="absolute top-4 left-4 bg-red-500 text-white p-2 rounded-full shadow-lg"
									whileHover={{ scale: 1.2 }}
									transition={{ type: "spring", stiffness: 300 }}
								>
									{icon}
								</motion.div>

								{/* Image Container */}
								<div className="w-full h-48 flex items-center justify-center bg-gray-200 relative overflow-hidden">
									<motion.img
										src={item.imgSrc}
										alt={item.name}
										className="max-h-full max-w-full object-contain cursor-pointer"
										loading="lazy"
										onClick={() => openModal(item.imgSrc, item.name)}
										whileHover={{ scale: 1.05 }}
										transition={{ duration: 0.3 }}
									/>
									{/* Overlay on Hover */}
									<motion.div
										className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-opacity duration-300"
										initial={{ opacity: 0 }}
										animate={{ opacity: 0 }}
										whileHover={{ opacity: 0.2 }}
									></motion.div>
								</div>

								{/* Content */}
								<div className="p-6">
									<h3 className="text-xl font-semibold text-red-500 mb-4 transition-colors duration-300 group-hover:text-red-600">
										{item.name}
									</h3>
									{item.description && (
										<p className="text-gray-700 text-sm h-20 overflow-hidden">
											{item.description}
										</p>
									)}
								</div>

								{/* Additional Hover Effect: Show "View Details" */}
							</motion.div>
						);
					})}
				</div>
			</div>

			{/* Modal for Fullscreen Image */}
			<AnimatePresence>
				{showModal && (
					<Modal
						showModal={showModal}
						setShowModal={setShowModal}
						imgSrc={currentImg}
						name={currentName}
					/>
				)}
			</AnimatePresence>

			{/* Footer */}
			<footer className="w-full bg-black text-white py-10">
				<div className="container mx-auto text-center px-6">
					<ul className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-8 mb-6">
						<li>
							<NavLink
								to="/"
								exact
								className="hover:text-red-500 transition-colors duration-300"
							>
								Home
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/about"
								className="hover:text-red-500 transition-colors duration-300"
							>
								About Us
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/locations"
								className="hover:text-red-500 transition-colors"
							>
								Locations
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/rental"
								className="hover:text-red-500 transition-colors duration-300"
							>
								Rental
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/contact"
								className="hover:text-red-500 transition-colors duration-300"
							>
								Contact
							</NavLink>
						</li>
					</ul>
					<p className="text-sm">
						© {new Date().getFullYear()} Dera Film. All rights reserved.
					</p>
				</div>
			</footer>
		</div>
	);
};

export default Rent;
