// src/components/Contact.jsx

import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import { motion } from "framer-motion";

const Contact = () => {
	// State to manage form inputs and submission status
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [formSubmitted, setFormSubmitted] = useState(false);

	// Handler for input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Handler for form submission
	const handleSubmit = (e) => {
		e.preventDefault();

		// Basic validation (ensure all fields are filled)
		if (formData.name && formData.email && formData.message) {
			// Simulate form submission (since there's no backend)
			console.log("Form Data Submitted:", formData);

			// Reset form and show success message
			setFormData({ name: "", email: "", message: "" });
			setFormSubmitted(true);

			// Hide success message after a delay
			setTimeout(() => {
				setFormSubmitted(false);
			}, 5000);
		} else {
			alert("Please fill in all fields.");
		}
	};

	return (
		<div className="bg-white text-black min-h-screen">
			{/* Navbar */}
			<Navbar />

			{/* Spacer for fixed header */}
			<div className="h-24"></div>

			{/* Contact Section */}
			<section
				className="bg-cover bg-center"
				style={{ backgroundImage: 'url("/contact-bg.jpeg")' }}
			>
				<div className="bg-[#394953] bg-opacity-60 py-20">
					<div className="container mx-auto px-6">
						<motion.h2
							className="text-4xl font-bold text-center mb-12 text-white"
							initial={{ opacity: 0, y: -50 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 1 }}
						>
							Get in Touch
						</motion.h2>
						<div className="flex flex-col md:flex-row items-start md:items-center justify-between">
							{/* Contact Information */}
							<motion.div
								className="md:w-1/2 text-white mb-12 md:mb-0"
								initial={{ opacity: 0, x: -50 }}
								animate={{ opacity: 1, x: 0 }}
								transition={{ duration: 1 }}
							>
								<h3 className="text-2xl font-semibold mb-6">
									We'd Love to Hear From You
								</h3>
								<p className="text-lg mb-6">
									Whether you have a question about our services, pricing, or
									anything else, our team is ready to answer all your questions.
								</p>
								<div className="space-y-4">
									<div className="flex items-start">
										<FaMapMarkerAlt className="text-red-500 text-2xl mr-4 mt-1" />
										<div>
											<h4 className="font-semibold text-xl">Address</h4>
											<p>St. Hanry Dynan, Ulpiana C15</p>
											<p>Prishtina 10000, Republic of Kosova</p>
										</div>
									</div>
									<div className="flex items-start">
										<FaEnvelope className="text-red-500 text-2xl mr-4 mt-1" />
										<div>
											<h4 className="font-semibold text-xl">Email</h4>
											<p>
												<a
													href="mailto:latifhasolli@gmail.com"
													className="hover:underline"
												>
													latifhasolli@gmail.com
												</a>
											</p>
										</div>
									</div>
									<div className="flex items-start">
										<FaPhoneAlt className="text-red-500 text-2xl mr-4 mt-1" />
										<div>
											<h4 className="font-semibold text-xl">Phone</h4>
											<p>
												<a href="tel:+38344227470" className="hover:underline">
													+383 44 227 470
												</a>
											</p>
										</div>
									</div>
								</div>
							</motion.div>

							{/* Contact Form */}
							<motion.div
								className="md:w-1/2"
								initial={{ opacity: 0, x: 50 }}
								animate={{ opacity: 1, x: 0 }}
								transition={{ duration: 1 }}
							>
								<form
									className="bg-white bg-opacity-90 p-8 rounded-lg shadow-lg"
									onSubmit={handleSubmit}
								>
									{formSubmitted && (
										<div className="mb-6 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
											Your message has been sent successfully!
										</div>
									)}
									<div className="mb-6">
										<label
											htmlFor="name"
											className="block text-lg font-semibold mb-2"
										>
											Name
										</label>
										<input
											type="text"
											id="name"
											name="name"
											value={formData.name}
											onChange={handleChange}
											className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500"
											placeholder="Your Name"
											required
										/>
									</div>
									<div className="mb-6">
										<label
											htmlFor="email"
											className="block text-lg font-semibold mb-2"
										>
											Email
										</label>
										<input
											type="email"
											id="email"
											name="email"
											value={formData.email}
											onChange={handleChange}
											className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500"
											placeholder="Your Email"
											required
										/>
									</div>
									<div className="mb-6">
										<label
											htmlFor="message"
											className="block text-lg font-semibold mb-2"
										>
											Message
										</label>
										<textarea
											id="message"
											name="message"
											rows="5"
											value={formData.message}
											onChange={handleChange}
											className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500"
											placeholder="Your Message"
											required
										></textarea>
									</div>
									<button
										type="submit"
										className="w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-600 transition-colors"
									>
										Send Message
									</button>
								</form>
							</motion.div>
						</div>
					</div>
				</div>
			</section>

			{/* Footer */}
			<Footer />
		</div>
	);
};

const Footer = () => {
	return (
		<footer className="w-full bg-black text-white py-6">
			<div className="container mx-auto text-center px-6">
				<ul className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6 mb-4">
					<li>
						<NavLink
							to="/"
							exact
							className="hover:text-red-500 transition-colors"
						>
							Home
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/about"
							className="hover:text-red-500 transition-colors"
						>
							About Us
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/locations"
							className="hover:text-red-500 transition-colors"
						>
							Locations
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/rental"
							className="hover:text-red-500 transition-colors"
						>
							Rental
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/contact"
							className="hover:text-red-500 transition-colors"
						>
							Contact
						</NavLink>
					</li>
				</ul>
				<p>© 2024 Dera Film. All rights reserved.</p>
			</div>
		</footer>
	);
};

export default Contact;
