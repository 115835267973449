// src/components/Modal.jsx

import { FaTimes } from "react-icons/fa";
import React from "react";
import { motion } from "framer-motion";

const backdrop = {
	visible: { opacity: 1 },
	hidden: { opacity: 0 },
};

const modal = {
	hidden: {
		scale: 0.8,
		opacity: 0,
	},
	visible: {
		scale: 1,
		opacity: 1,
		transition: { duration: 0.3 },
	},
};

const Modal = ({ showModal, setShowModal, imgSrc, name }) => {
	if (!showModal) return null;

	return (
		<motion.div
			className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50"
			variants={backdrop}
			initial="hidden"
			animate="visible"
			exit="hidden"
			onClick={() => setShowModal(false)}
		>
			<motion.div
				className="relative bg-white rounded-lg overflow-hidden"
				variants={modal}
				onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
			>
				<button
					className="absolute top-4 right-4 text-gray-700 hover:text-red-500 transition-colors"
					onClick={() => setShowModal(false)}
				>
					<FaTimes size={24} />
				</button>
				<img
					src={imgSrc}
					alt={name}
					className="w-full h-auto max-h-[90vh] object-contain"
				/>
			</motion.div>
		</motion.div>
	);
};

export default Modal;
