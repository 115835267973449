// src/components/Locations.jsx

import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import { motion } from "framer-motion";

const locationsData = [
	{
		id: 1,
		name: "Serene Coastal Beach",
		image: "/beachinvlora.jpeg",
	},
	{
		id: 2,
		name: "Ancient City of a Thousand Windows",
		image: "/berat.jpeg",
	},
	{
		id: 3,
		name: "Rainy Urban Streets",
		image: "/citynightrain.jpeg",
	},
	{
		id: 4,
		name: "Mountainous Island Fortress",
		image: "/fortresinbeach.jpeg",
	},
	{
		id: 5,
		name: "Secluded Valley Church",
		image: "/natureChurchandMountains.jpeg",
	},
	{
		id: 6,
		name: "River Between Mountains",
		image: "/natureriver.jpeg",
	},
	{
		id: 7,
		name: "Traditional Market Road",
		image: "/oldcity2.jpeg",
	},
	{
		id: 8,
		name: "Historic Junction Shop",
		image: "/oldcityTradicjonal.jpeg",
	},
	{
		id: 9,
		name: "Enormous Hidden Cave",
		image: "/shpella.jpeg",
	},
	{
		id: 10,
		name: "Modern Cityscape by Day",
		image: "/tiranacenterday.jpeg",
	},
	{
		id: 11,
		name: "Illuminated City Nights",
		image: "/tiranainnight.jpeg",
	},
];

const Locations = () => {
	const [selectedLocation, setSelectedLocation] = useState(null);

	if (selectedLocation) {
		return (
			<div className="bg-white text-black">
				<Navbar />
				<div className="h-24"></div>
				<motion.div
					className="relative"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 1 }}
				>
					<motion.img
						src={selectedLocation.image}
						alt={selectedLocation.name}
						className="w-full h-[80vh] object-cover"
						initial={{ scale: 1.1 }}
						animate={{ scale: 1 }}
						transition={{ duration: 1 }}
					/>
					<motion.div
						className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ delay: 0.5, duration: 1 }}
					>
						<h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
							{selectedLocation.name}
						</h2>
						<motion.button
							onClick={() => setSelectedLocation(null)}
							className="bg-red-500 text-white px-6 py-2 rounded hover:bg-red-600 transition-all transform hover:scale-105 shadow-md"
							whileHover={{ scale: 1.1 }}
							transition={{ duration: 0.3 }}
						>
							Back to Locations
						</motion.button>
					</motion.div>
				</motion.div>
				<Footer />
			</div>
		);
	}

	return (
		<div className="bg-white text-black">
			<Navbar />
			<div className="h-24"></div>
			{/* Spacer for fixed header */}
			<section className="py-20">
				<motion.h2
					className="text-4xl font-bold text-center mb-6 text-red-500"
					initial={{ opacity: 0, y: -50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 1 }}
				>
					Location Scouting
				</motion.h2>
				<motion.p
					className="text-lg text-center max-w-3xl mx-auto mb-12 px-6"
					initial={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 1, delay: 0.2 }}
				>
					Discover breathtaking locations perfect for your next film project.
					Our curated selection offers a diverse range of sceneries to match any
					creative vision.
				</motion.p>
				<div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-6">
					{locationsData.map((location, index) => (
						<motion.div
							key={location.id}
							className="cursor-pointer shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow"
							onClick={() => setSelectedLocation(location)}
							initial={{ opacity: 0, y: 30 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5, delay: index * 0.1 }}
							whileHover={{ scale: 1.02 }}
						>
							<div className="relative">
								<img
									src={location.image}
									alt={location.name}
									className="w-full h-64 md:h-72 lg:h-80 object-cover"
								/>
								<div className="absolute inset-0 bg-black bg-opacity-30 flex items-end">
									<h3 className="text-lg font-semibold text-white p-4">
										{location.name}
									</h3>
								</div>
							</div>
						</motion.div>
					))}
				</div>
			</section>
			<Footer />
		</div>
	);
};

const Footer = () => {
	return (
		<footer className="w-full bg-black text-white py-6">
			<div className="container mx-auto text-center px-6">
				<ul className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6 mb-4">
					<li>
						<NavLink
							to="/"
							exact
							className="hover:text-red-500 transition-colors"
						>
							Home
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/about"
							className="hover:text-red-500 transition-colors"
						>
							About Us
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/locations"
							className="hover:text-red-500 transition-colors"
						>
							Locations
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/rental"
							className="hover:text-red-500 transition-colors"
						>
							Rental
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/contact"
							className="hover:text-red-500 transition-colors"
						>
							Contact
						</NavLink>
					</li>
				</ul>
				<p>© 2024 Dera Film. All rights reserved.</p>
			</div>
		</footer>
	);
};

export default Locations;
